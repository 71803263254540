import React from 'react';
import styled from 'styled-components';

export interface TableBodyProps {
    children?: React.ReactElement | React.ReactElement[] | null
}

const TableBodyStyled = styled.tbody<TableBodyProps>`
  tr {
    &:nth-child(odd) {
      background-color: white;
    }
    
    td {
      vertical-align: center;
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

const TableBody = ({children}: TableBodyProps) => {
    return <TableBodyStyled>
        {children}
    </TableBodyStyled>
}

export default TableBody;
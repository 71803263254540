import React from 'react';
import './App.css';
import Router from "./config/routes";

function App() {
    return (
        <div className="main-container">
            <Router/>
        </div>
    );
}

export default App;

import React, {useEffect, useState} from "react";
import Container from "../../../../components/Container";
import Flex, {FlexItem} from "../../../../components/Flex";
import Tag from "../../../../components/Tag";
import Title from "../../../../components/Title";
import {Size} from "../../../../props/common";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../../config/env";
import {_sortMatchesByTableNumber, MatchProps, PlayerProps, teamEmptyMatch, TeamMatchProps} from "../../matchProps";
import {fetchAPI} from "../../../../config/fetch";
import Select from "../../../../components/select/Select";
import Option from "../../../../components/select/Option";
import Button from "../../../../components/Button";
import {green, yellow} from "../../../../config/style";
import Spacer from "../../../../components/Spacer";
import Swal from "sweetalert2";
import Divider from "../../../../components/Divider";
import {getBestMatchingPlayer} from "../../../../utils/getBestMatchingPlayer";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ["websocket"]});

const ViewSingleTeamMatch = ({queryParams}: any) => {
    const [match, setMatch] = useState<TeamMatchProps>(teamEmptyMatch);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);
    const [disableButtons, setDisableButtons] = useState<boolean>(false);

    interface SingleMatchDataProps {
        id: number;
        player?: string;
        teamNumber?: number;
        newScore?: number;
        playerNumber?: "ONE" | "TWO";
    }

    const _setDelay = () => {
        setDisableButtons(true);
        setTimeout(() => {
            setDisableButtons(false);
        }, 500);
    };

    const _freezeScores = () => {
        Swal.fire({
            title: "Do you really want to freeze scores ?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                fetchAPI(`/matches/team/update-frozen`, "POST", {...match, is_frozen: true})
                    .then(res => {
                        if (res.errMsg) alert(res.errMsg);
                        setReadyToUpdate(true);
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Thank you !",
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(err => {
                        alert(`An error occurred when updating score => ${err}"`);
                    });
            }
        });
    };

    const _updateSingleMatch = (updateType: "playerName" | "score", singleMatchData: SingleMatchDataProps, currentPlayer: string | null = null) => {
        let matchTmp: TeamMatchProps = match;
        let singleMatchIndex: number = matchTmp.all_single_matches.findIndex((singleMatch: MatchProps) => singleMatch.id === singleMatchData.id);

        _setDelay();

        const update = (substitution: boolean = false) => {
            if (matchTmp.all_single_matches[singleMatchIndex].table_number < 99) {
                matchTmp.all_single_matches[singleMatchIndex].table_number = singleMatchIndex + 1;
            }

            const currentPlayerOne = matchTmp.all_single_matches[singleMatchIndex].player_one.fullname;
            const currentPlayerTwo = matchTmp.all_single_matches[singleMatchIndex].player_two.fullname;
            const updatedPlayer = singleMatchData.player || "null";

            if (singleMatchData.teamNumber === 1 && singleMatchData.player != null) {
                matchTmp.all_single_matches[singleMatchIndex].player_one.fullname = substitution ? currentPlayerOne : updatedPlayer;
                matchTmp.all_single_matches[singleMatchIndex].substitute_player_one.fullname = substitution ? updatedPlayer : "";
            } else if (singleMatchData.player != null) {
                matchTmp.all_single_matches[singleMatchIndex].player_two.fullname = substitution ? currentPlayerTwo : updatedPlayer;
                matchTmp.all_single_matches[singleMatchIndex].substitute_player_two.fullname = substitution ? updatedPlayer : "";
            }

            fetchAPI(`/matches/individual/update/${singleMatchData.id}`, "POST", matchTmp.all_single_matches[singleMatchIndex])
                .then(res => {
                    if (res.errMsg) alert(res.errMsg);
                    socket.emit("update-score", matchTmp);
                    setReadyToUpdate(true);
                })
                .catch(err => {
                    alert(`An error occurred when updating score => ${err}"`);
                });
        };

        if (updateType === "playerName" && singleMatchData.player !== "To be defined") {
            if (currentPlayer !== "To be defined") {
                Swal.fire({
                    title: "Is this a substitution ?",
                    showDenyButton: true,
                    denyButtonText: "No",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        update();
                    } else {
                        update();
                    }
                });
            } else {
                update();
            }
        } else if (updateType === "score" && singleMatchData.id > 0) {
            let playerScoreKey: string;
            singleMatchData.playerNumber === "ONE" ? playerScoreKey = "score_player_one" : playerScoreKey = "score_player_two";
            // @ts-ignore
            matchTmp.all_single_matches[singleMatchIndex][playerScoreKey] = singleMatchData.newScore >= 0 ? singleMatchData.newScore : 0;

            fetchAPI(`/matches/individual/update-scores/${singleMatchData.id}`, "POST", {
                ...matchTmp.all_single_matches[singleMatchIndex],
                teamUpdate: true,
                teamMatchData: matchTmp
            })
                .then(res => {
                    if (res.errMsg) alert(res.errMsg);
                    socket.emit("update-score", matchTmp.all_single_matches[singleMatchIndex]);
                    setReadyToUpdate(true);
                })
                .catch(err => {
                    alert(`An error occurred when updating score => ${err}"`);
                });
        }
    };

    const _selectPlayers = (players: PlayerProps[] = [], teamNumber: number, singleMatchId: number) => {
        const singleMatch: MatchProps | undefined = match.all_single_matches.find((singleMatch: MatchProps) => singleMatch.id === singleMatchId);
        if (!singleMatch) return null;
        let currentPlayer: string = getBestMatchingPlayer(singleMatch.player_one, singleMatch.substitute_player_one);
        if (teamNumber === 2) currentPlayer = getBestMatchingPlayer(singleMatch.player_two, singleMatch.substitute_player_two);

        console.log(singleMatch);
        console.log(currentPlayer);

        return <Select
            defaultValue={currentPlayer}
            onChange={(event: any) => _updateSingleMatch("playerName", {
                id: singleMatchId,
                teamNumber,
                player: event.target.value
            }, currentPlayer)}
            disabled={match.is_frozen}>
            {
                players.map((player: PlayerProps, index: number) => {
                    return <Option key={index} value={player.fullname}>
                        {player.fullname}
                    </Option>;
                })
            }
        </Select>;
    };

    const _generatePlayerTags = (match: TeamMatchProps) => {
        const playerTags: React.ReactElement[] = [];
        const playerToBeDefined: PlayerProps = {
            firstname: "",
            lastname: "",
            fullname: "To be defined",
            club: "",
            club_short: "",
            country: "",
            birthdate: "",
            star: 0,
            licence_number: ""
        };
        const sortedSingleMatches: MatchProps[] = _sortMatchesByTableNumber(match.all_single_matches);

        sortedSingleMatches.forEach((singleMatch: MatchProps) => {
            playerTags.push(
                <Flex alignItems="flex-start" key={singleMatch.id}>
                    <FlexItem width="42.5vw">
                        <Flex>
                            <Button borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                    height={`${Size.LEVEL_TEN}px`}
                                    onClick={() => _updateSingleMatch("score", {
                                        newScore: singleMatch.score_player_one - 1,
                                        id: singleMatch.id,
                                        playerNumber: "ONE"
                                    })}
                                    disabled={match.is_frozen || disableButtons}
                                    fontSize={`${Size.LEVEL_THREE}px`}
                                    padding="8px 20px">-</Button>
                            <Tag>{_selectPlayers([playerToBeDefined, ...match.team_one.all_players], 1, singleMatch.id)}</Tag>
                            <Button color={green} borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                    height={`${Size.LEVEL_TEN}px`}
                                    onClick={() => _updateSingleMatch("score", {
                                        newScore: singleMatch.score_player_one + 1,
                                        id: singleMatch.id,
                                        playerNumber: "ONE"
                                    })}
                                    disabled={match.is_frozen || disableButtons}
                                    fontSize={`${Size.LEVEL_THREE}px`}
                                    padding="8px 20px">+</Button>
                        </Flex>
                    </FlexItem>
                    <FlexItem width="12.5vw">
                        <Tag height={Size.LEVEL_TEN}>
                            {singleMatch.score_player_one}
                            <Divider direction="vertical" margin={Size.LEVEL_TWO}/>
                            {singleMatch.score_player_two}
                        </Tag>
                    </FlexItem>
                    <FlexItem width="42.5vw">
                        <Flex>
                            <Button color={green} borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                    height={`${Size.LEVEL_TEN}px`}
                                    onClick={() => _updateSingleMatch("score", {
                                        newScore: singleMatch.score_player_two + 1,
                                        id: singleMatch.id,
                                        playerNumber: "TWO"
                                    })}
                                    disabled={match.is_frozen || disableButtons}
                                    fontSize={`${Size.LEVEL_THREE}px`}
                                    padding="8px 20px">+</Button>
                            <Tag>{_selectPlayers([playerToBeDefined, ...match.team_two.all_players], 2, singleMatch.id)}</Tag>
                            <Button borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                    height={`${Size.LEVEL_TEN}px`}
                                    onClick={() => _updateSingleMatch("score", {
                                        newScore: singleMatch.score_player_two - 1,
                                        id: singleMatch.id,
                                        playerNumber: "TWO"
                                    })}
                                    disabled={match.is_frozen || disableButtons}
                                    fontSize={`${Size.LEVEL_THREE}px`}
                                    padding="8px 20px">-</Button>
                        </Flex>
                    </FlexItem>
                </Flex>);
        });

        return playerTags;
    };

    useEffect(() => {
        socket.on("updated-scores", (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        socket.on("updated-current-round", (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2025/team/rounds`)
            .then(matches => {
                if (Object.keys(queryParams).length) {
                    setMatch(matches.filter((match: TeamMatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to)[0]);
                } else {
                    setMatch(teamEmptyMatch);
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, queryParams]);

    return (
        <Flex>
            <Container padding={Size.LEVEL_ZERO}>
                {
                    match.id >= 0 ?
                        <>
                            <Spacer size={Size.LEVEL_TWO}/>
                            <Flex alignItems="flex-end">
                                <FlexItem width="42.5vw">
                                    <Tag fontSize={Size.LEVEL_FOUR}>{match.team_one.name}</Tag>
                                </FlexItem>
                                <div style={{width: "12.5vw", marginRight: "16px", textAlign: "center"}}>
                                    <Button width="100%" borderRadius="8px 8px 0 0" color={yellow}
                                            onClick={_freezeScores} disabled={match.is_frozen}
                                            height={`${Size.LEVEL_FIVE}px`} fontSize="14px"
                                            padding={`${Size.LEVEL_ZERO}px`}>
                                        VALIDATE ✓
                                    </Button>
                                    <Flex>
                                        <FlexItem width="100%" marginTop={Size.LEVEL_ZERO}>
                                            <Tag borderRadius="0 0 8px 8px" fontSize={Size.LEVEL_FOUR}
                                                 height={Size.LEVEL_TEN}>
                                                {match.score_team_one}
                                                <Divider direction="vertical"/>
                                                {match.score_team_two}
                                            </Tag>
                                        </FlexItem>
                                    </Flex>
                                </div>
                                <FlexItem width="42.5vw">
                                    <Tag fontSize={Size.LEVEL_FOUR}>{match.team_two.name}</Tag>
                                </FlexItem>
                            </Flex>
                            <Spacer size={Size.LEVEL_TWO}/>
                            {_generatePlayerTags(match)}
                        </>
                        : <Title>No match found</Title>
                }
            </Container>
        </Flex>
    );
};

export default ViewSingleTeamMatch;

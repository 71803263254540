import React from "react";
import styled from "styled-components";
import {Size} from "../props/common";
import {main} from "../config/style";

interface LinkPros {
    size?: Size;
    children?: any;
    marginBottom?: Size;
    href?: string;
    onClick?: any;
    noUnderline?: boolean;
}

const LinkStyled = styled.a<LinkPros>`
    text-decoration: ${props => props.noUnderline ? "none" : "underline"};
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    cursor: pointer;
    transition: 0.1s;
    font-size: ${props => props.size}px;

    &:hover {
        color: ${main};
    }
`;

const Link = ({size = Size.LEVEL_THREE, children, href, onClick, noUnderline = false}: LinkPros) => {
    return <LinkStyled size={size} href={href} onClick={onClick} noUnderline={noUnderline}>
        {children}
    </LinkStyled>;
};

export default Link;
import React from "react";
import styled from "styled-components";
import {Size} from "../props/common";
import {CountriesEnum} from "../screens/match/matchProps";

export enum ClubLogos {
    ISSY = "issy"
}

interface TagProps {
    children?: any;
    borderRadius?: string;
    fontSize?: Size | string;
    borderColor?: "transparent" | string;
    height?: Size;
    boxShadow?: boolean;
    backgroundFlag?: CountriesEnum | string;
    clubLogo?: ClubLogos | string;
    clubLogoPosition?: "left" | "right";
    small?: boolean;
    star?: number;
    textAlign?: "center" | "left" | "right";
}

interface BackgroundFlagProps {
    borderRadius?: string;
}

const BackgroundFlag = styled.img<BackgroundFlagProps>`
    width: 100%;
    height: 100%;
    border-radius: ${props => props.borderRadius};
`;

const TagStyled = styled.div<TagProps>`
    border-radius: ${props => props.borderRadius};
    border-style: solid;
    border-width: ${props => props.borderColor === "transparent" ? 0 : "2px"};
    border-color: ${props => props.borderColor};
    width: 100%;
    height: ${props => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    text-align: center;
    box-shadow: ${props => props.boxShadow ? "0px 0px 15px 5px #e8e8e8" : "none"};
    position: relative;
    ${props => !props.borderColor && `border: 2px solid color-mix(in srgb, white 80%, black 20%);`};

    div {
        position: absolute;
        font-weight: bold;
        font-size: ${props => props.fontSize}px;
        background-color: white;
        width: ${props => props.small ? "calc(100% - 8px)" : "calc(100% - 13px)"};
        height: ${props => props.small ? "calc(100% - 8px)" : "calc(100% - 13px)"};
        border-radius: ${props => props.borderRadius};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 ${props => props.clubLogo && props.clubLogoPosition === "left" ? "calc(75px + 32px)" : 0};
    }

    img.club-logo {
        position: absolute;
        left: ${props => props.clubLogoPosition === "left" ? "24px" : "calc(100% - (24px + 75px))"};
        z-index: 999;
        height: ${props => props.small ? "50px" : "65px"};
    }

    img.star {
        position: absolute;
        left: ${props => props.clubLogoPosition === "left" ? "calc(100% - 90px)" : "24px"};
        z-index: 999;
        height: ${props => props.small ? "40px" : "55px"};
    }
`;

export const Tag = ({
                        children,
                        borderRadius = `${Size.LEVEL_ONE}px`,
                        fontSize = Size.LEVEL_FOUR,
                        borderColor = "transparent",
                        height = Size.LEVEL_TEN,
                        boxShadow = false,
                        backgroundFlag,
                        clubLogo,
                        clubLogoPosition = "left",
                        small = false,
                        star = 0
                    }: TagProps) => {
    return <TagStyled
        borderRadius={borderRadius} fontSize={fontSize} borderColor={borderColor} height={height}
        boxShadow={boxShadow} backgroundFlag={backgroundFlag} clubLogo={clubLogo}
        clubLogoPosition={clubLogoPosition} small={small} star={star}
    >
        {
            backgroundFlag && <BackgroundFlag src={`/assets/flags/${backgroundFlag}.png`} borderRadius={borderRadius}/>
        }
        {
            clubLogo && <>
                <img className="club-logo"
                     src={clubLogo ? `/assets/logos/${clubLogo.replace(/\s/g, "")}.png` : "/assets/transparent.png"}
                     alt=""
                />
                <img className="star"
                     src={star ? require("../assets/icons/star.png") : require("../assets/transparent.png")}
                     alt="star"
                />
            </>
        }
        <div>
            {children}
        </div>
    </TagStyled>;
};

export default Tag;

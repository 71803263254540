import React from 'react';
import styled from 'styled-components';

export interface OptionProps {
    children: React.ReactElement | React.ReactElement[] | string | string[]
    value?: string
}

const OptionStyled = styled.option`
  font-weight: bold;
  padding: 4px;
`;

export const Option = ({children, value}: OptionProps) => {
    return <OptionStyled value={value}>{children}</OptionStyled>;
}

export default Option
export enum Size {
    LEVEL_ZERO = 0,
    LEVEL_ONE = 8,
    LEVEL_TWO = 16,
    LEVEL_THREE = 24,
    LEVEL_FOUR = 32,
    LEVEL_FIVE = 40,
    LEVEL_SIX = 48,
    LEVEL_SEVEN = 56,
    LEVEL_EIGHT = 64,
    LEVEL_NINE = 72,
    LEVEL_TEN = 80,
    LEVEL_ELEVEN = 88,
    LEVEL_TWELVE = 96,
    LEVEL_THIRTEEN = 104,
    LEVEL_FOURTEEN = 112,
    LEVEL_FIFTEEN = 120,
    LEVEL_SIXTEEN = 128,
    LEVEL_SEVENTEEN = 136,
    LEVEL_EIGHTEEN = 144,
    LEVEL_NINETEEN = 152,
    LEVEL_TWENTY = 160,
    LEVEL_TWENTY_ONE = 168,
    LEVEL_TWENTY_TWO = 176,
    LEVEL_TWENTY_THREE = 184,
    LEVEL_TWENTY_FOUR = 192,
}

export interface GroupProps {
    category: string;
    group: string;
    players: any[]
}
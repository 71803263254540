import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Auth from "../screens/Auth";
import ViewMatch from "../screens/match/individual/View";
import UpdateMatch from "../screens/match/individual/Update";
import ImportData from "../screens/ImportData";
import Dashboard from "../screens/Dashboard";
import Results from "../screens/Results";
import ViewMatchesTeam from "../screens/match/team/view/View";
import UpdateMatchTeam from "../screens/match/team/update/UpdateMatches";
import TeamDashboard from "../screens/team/Dashboard";
import IndividualGroups from "../screens/groups/Individual";
import IndividualRounds from "../screens/rounds/Individual";
import TeamRounds from "../screens/rounds/Team";
import TeamGroups from "../screens/groups/Team";
import Setup from "../screens/Setup";
import AllMatches from "../screens/AllMatches";
import CurrentRound from "../screens/current-round";

const Router = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Auth/>}/>
            <Route path="/all-matches/:type" element={<AllMatches/>}/>
            <Route path="/auth" element={<Auth/>}/>
            <Route path="/current-round" element={<CurrentRound/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/team/dashboard" element={<TeamDashboard/>}/>
            <Route path="/groups/individual" element={<IndividualGroups/>}/>
            <Route path="/groups/team" element={<TeamGroups/>}/>
            <Route path="/import-data" element={<ImportData/>}/>
            <Route path="/match/individual/view" element={<ViewMatch/>}/>
            <Route path="/match/individual/update" element={<UpdateMatch/>}/>
            <Route path="/match/team/view" element={<ViewMatchesTeam/>}/>
            <Route path="/match/team/update" element={<UpdateMatchTeam/>}/>
            <Route path="/setup" element={<Setup/>}/>
            <Route path="/results" element={<Results/>}/>
            <Route path="/rounds/individual" element={<IndividualRounds/>}/>
            <Route path="/rounds/team" element={<TeamRounds/>}/>
        </Routes>
    </BrowserRouter>;
};

export default Router;

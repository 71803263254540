import React from "react";
import styled from "styled-components";
import {main} from "../config/style";

type SwitchProps = {
    isOn: boolean;
    onToggle: () => void;
    label?: string;
};

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Label = styled.span`
    margin-left: 8px;
    font-size: 16px;
    color: #333;
`;

const Toggle = styled.div<{ isOn: boolean }>`
    width: 70px;
    height: 35px;
    border-radius: 20px;
    background-color: ${({isOn}) => (isOn ? main : "#ccc")};
    display: flex;
    align-items: center;
    padding: 6px;
    transition: background-color 0.3s ease;
    position: relative;
`;

const Knob = styled.div<{ isOn: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    left: ${({isOn}) => (isOn ? "calc(100% - 30px)" : "6px")};
    transition: left 0.3s ease;
`;

const Switch: React.FC<SwitchProps> = ({isOn, onToggle, label}) => {
    return (
        <SwitchContainer onClick={onToggle}>
            <Toggle isOn={isOn}>
                <Knob isOn={isOn}/>
            </Toggle>
            {label && <Label>{label}</Label>}
        </SwitchContainer>
    );
};

export default Switch;

import React from "react";
import styled from "styled-components";
import {Size} from "../props/common";
import {main, grey75} from "../config/style";

interface ButtonPros {
    size?: Size;
    children?: any;
    marginBottom?: Size;
    height?: string;
    borderRadius?: string;
    color?: string;
    onClick?: any;
    disabled?: boolean;
    width?: string;
    fontSize?: string;
    padding?: string;
}

const ButtonStyled = styled.button<ButtonPros>`
    border-radius: ${props => props.borderRadius};
    padding: ${Size.LEVEL_ONE}px ${Size.LEVEL_THREE}px;
    height: ${props => props.height};
    background-color: ${props => props.color};
    border: 2px solid color-mix(in srgb, ${props => props.color} 80%, black 20%);
    color: color-mix(in srgb, ${props => props.color} 20%, black 80%);
    font-weight: bolder;
    font-size: ${props => props.fontSize};
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.13s;
    width: ${props => props.width};

    &:disabled {
        cursor: no-drop;
        background-color: ${grey75};
        color: #fff;
        border-color: ${grey75};
    }
`;

const Button = ({
                    size = Size.LEVEL_THREE,
                    children,
                    height = `${Size.LEVEL_SIX}px`,
                    marginBottom = Size.LEVEL_THREE,
                    borderRadius = `${Size.LEVEL_ONE}px`,
                    color = main,
                    onClick = null,
                    disabled = false,
                    width = "auto",
                    padding = `${Size.LEVEL_ZERO}px`,
                    fontSize = `${Size.LEVEL_THREE}px`
                }: ButtonPros) => {
    return <ButtonStyled
        size={size} marginBottom={marginBottom} height={height} borderRadius={borderRadius}
        color={color} onClick={onClick} disabled={disabled} width={width} fontSize={fontSize}
        padding={padding}
    >
        {children}
    </ButtonStyled>;
};

export default Button;
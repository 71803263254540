import {_sortMatchesByTableNumber, MatchProps, TeamMatchProps} from "../../screens/match/matchProps";
import Table from "../table/Table";
import TableHead from "../table/TableHead";
import TableBody from "../table/TableBody";
import React, {useEffect, useState} from "react";
import {fetchAPI} from "../../config/fetch";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../config/env";
import Flex, {FlexItem} from "../Flex";
import Container from "../Container";
import {Size} from "../../props/common";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ["websocket"]});

const displayAllMatchesTeam = (allMatches: Array<TeamMatchProps>) => {
    return <Table>
        <TableHead>
            <tr>
                <td width="10%">Tables</td>
                <td width="30%">Team 1</td>
                <td width="30%">Team 2</td>
                <td width="30%">Referee</td>
            </tr>
        </TableHead>
        <TableBody>
            {
                allMatches.map((match: TeamMatchProps, index) => {
                    return <tr key={index}>
                        <td>{match.table_number} - {match.table_number + 3}</td>
                        <td>{match.team_one.name}</td>
                        <td>{match.team_two.name}</td>
                        <td>{match.referee}</td>
                    </tr>;
                })
            }
        </TableBody>
    </Table>;
};

const displayAllMatchesIndividual = (allMatches: Array<MatchProps>) => {
    return <Table>
        <TableHead>
            <tr>
                <td width="10%">Table</td>
                <td width="25%">Player 1</td>
                <td width="25%">Player 2</td>
                <td width="25%">Referee</td>
                <td width="10%">Category</td>
            </tr>
        </TableHead>
        <TableBody>
            {
                allMatches.map((match: MatchProps, index) => {
                    return <tr key={index}>
                        <td>{match.table_number}</td>
                        <td>{match.player_one.fullname}</td>
                        <td>{match.player_two.fullname}</td>
                        <td>{match.referee}</td>
                        <td>{match.category}</td>
                    </tr>;
                })
            }
        </TableBody>
    </Table>;
};

const AllMatchesComponent = ({type}: { type: "individual" | "team" }) => {
    const [allIndividualMatches, setAllIndividualMatches] = useState<Array<MatchProps>>([]);
    const [allTeamMatches, setAllTeamMatches] = useState<Array<TeamMatchProps>>([]);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);

    useEffect(() => {
        socket.on("updated-current-round", (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        const urlToFetch = type === "individual" ? `/matches/Major2025/individual/rounds` : `/matches/Major2025/team/rounds`;
        fetchAPI(urlToFetch)
            .then(res => {
                if (type === "team") {
                    setAllTeamMatches(_sortMatchesByTableNumber(res));
                } else {
                    setAllIndividualMatches(_sortMatchesByTableNumber(res));
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, type]);

    if (allIndividualMatches.length || allTeamMatches.length) {
        if (type === "team") {
            return <Container padding={Size.LEVEL_TWO}>
                <Flex alignItems="center" justifyContent="center" flexDirection="column" height="75vh">
                    <FlexItem width="1366px">
                        {displayAllMatchesTeam(allTeamMatches)}
                    </FlexItem>
                </Flex>
            </Container>;
        }
        return <Container padding={Size.LEVEL_TWO}>
            <Flex alignItems="flex-start">
                <FlexItem>
                    {displayAllMatchesIndividual(allIndividualMatches.slice(0, 24))}
                </FlexItem>
                <FlexItem>
                    {displayAllMatchesIndividual(allIndividualMatches.slice(24))}
                </FlexItem>
            </Flex>
        </Container>;
    } else {
        return <Flex alignItems="center" justifyContent="center">
            <FlexItem>
                <h2>Matches are not available at the moment</h2>
            </FlexItem>
        </Flex>;
    }
};

export default AllMatchesComponent;
import React, {useEffect, useState} from 'react';
import Container from "../../../../components/Container";
import Flex, {FlexItem} from "../../../../components/Flex";
import Tag from "../../../../components/Tag";
import Title from "../../../../components/Title";
import {Size} from "../../../../props/common";
import Spacer from "../../../../components/Spacer";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../../config/env";
import {MatchProps, TeamMatchProps} from "../../matchProps";
import {fetchAPI} from "../../../../config/fetch";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ['websocket']});
const ViewMatchesTeam = () => {
    const [matches, setMatches] = useState<Array<TeamMatchProps>>([]);
    const [currentRound, setCurrentRound] = useState(1);
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<any>({});

    useEffect(() => {
        setQueryParams(Object.fromEntries(new URLSearchParams(window.location.search).entries()));
    }, [])

    useEffect(() => {
        socket.on('updated-scores', (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });

        socket.on('updated-current-round', (newCurrentRound, callback) => {
            setCurrentRound(newCurrentRound);
        });
    }, [])

    useEffect(() => {
        fetchAPI(`/matches/Major2025/team/rounds/${currentRound}`)
            .then(matches => {
                if (Object.keys(queryParams).length) {
                    setMatches(matches.filter((match: MatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to));
                } else {
                    setMatches(matches);
                }
                setReadyToUpdate(false);
            })
    }, [readyToUpdate, queryParams, currentRound]);

    return (
        <Container padding={Size.LEVEL_FOUR}>
            <Flex marginBottom={`${Size.LEVEL_FOUR}px`}>
                <Title marginBottom={Size.LEVEL_ZERO} size={Size.LEVEL_TEN}>Round {currentRound} - Major de
                    Paris 2022</Title>
            </Flex>
            <Spacer size={Size.LEVEL_SEVEN}/>
            <Flex>
                <Container padding={Size.LEVEL_ZERO}>
                    {matches.map(((match: TeamMatchProps) => {
                        return <Flex alignItems="flex-start" key={match.id}>
                            <FlexItem width="45vw">
                                <Tag>{match.team_one.name}</Tag>
                            </FlexItem>
                            <FlexItem width="5vw">
                                <Tag>{match.score_team_one}</Tag>
                            </FlexItem>
                            <FlexItem width="5vw">
                                <Tag>{match.score_player_two}</Tag>
                            </FlexItem>
                            <FlexItem width="45vw">
                                <Tag>{match.team_two.name}</Tag>
                            </FlexItem>
                        </Flex>
                    }))}
                </Container>
            </Flex>
        </Container>
    )
}

export default ViewMatchesTeam;

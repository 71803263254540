import React from 'react';
import DashboardLayout from "../components/DashboardLayout";
import InputText from "../components/Input";
import Spacer from "../components/Spacer";
import {Size} from "../props/common";
import Select from "../components/select/Select";
import Option from "../components/select/Option";

const Results = () => {

    return (
        <DashboardLayout currentPage="results">
            <InputText/>
            <Spacer size={Size.LEVEL_THREE}/>
            <Select>
                <Option>Catégorie</Option>
                <Option>OPEN</Option>
                <Option>WOMAN</Option>
            </Select>
        </DashboardLayout>
    )
}

export default Results;
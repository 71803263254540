import React from 'react';
import styled from 'styled-components';
import {TableHeadPros} from "./TableHead";
import {TableBodyProps} from "./TableBody";
import { main } from '../../config/style';

interface TablePros {
    children?: React.ReactElement<TableHeadPros & TableBodyProps>[] | any
}

const TableStyled = styled.table<TablePros>`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 14px;
  }
`;

const Table = ({children}: TablePros) => {
    return <div style={{border: `1px solid ${main}`, borderRadius: '8px', overflow: 'auto'}}>
        <TableStyled>
            {children}
        </TableStyled>
    </div>
}

export default Table;
import React, {useState} from 'react';
import {InputFile} from "../components/Input";
import Title from "../components/Title";
import {Size} from "../props/common";
import Container from "../components/Container";
import {main} from "../config/style";
import Flex from "../components/Flex";
import Button from "../components/Button";
import {fetchAPI} from "../config/fetch";

const ImportData = () => {
    const [file, setFile] = useState<any>()
    const handleFile = (e: any) => {
        setFile(e.target.files[0])
    }

    const submitForm = () => {
        const formData = new FormData();
        formData.append("file", file, "ninja.csv");

        fetchAPI("/file/import", "POST", formData, true)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => alert("File Upload Error"));
    };

    return (
        <Flex>
            <Container width="35vw" height="100vh" padding={Size.LEVEL_FIVE}>
                <Flex height="100%" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                    <Title size={Size.LEVEL_FIVE}>Import data</Title>
                    <form>
                        <InputFile onChange={handleFile}/>
                    </form>
                    <Button onClick={submitForm}>Log In</Button>
                </Flex>
            </Container>
            <Container height="100vh" backgroundColor={main}/>
        </Flex>
    )
}

export default ImportData;
import React from 'react';
import styled from 'styled-components';
import {grey150, main} from "../config/style";

interface NavProps {
    currentPage: 'groups' | 'rounds';
    parent: 'individual' | 'team';
}

const NavStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 24px;
    margin: auto 24px;
    color: ${grey150};
    text-decoration: none;
    padding-bottom: 8px;

    &.selected {
      border-bottom: 2px solid ${main};
    }
  }

  div {
    border-radius: 50%;
    border: 2px solid ${main};
  }
`;

const Nav = ({currentPage, parent}: NavProps) => {
    return <NavStyled>
        <a href={`/groups/${parent}`} className={currentPage === 'groups' ? 'selected' : ''}>Groups</a>
        <div/>
        <a href={`/rounds/${parent}`} className={currentPage === 'rounds' ? 'selected' : ''}>Rounds</a>
    </NavStyled>
}

export default Nav;
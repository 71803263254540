import React from "react";
import styled from "styled-components";
import {Size} from "../props/common";

interface ContainerProps {
    children?: any;
    width?: string;
    maxWidth?: string;
    height?: string;
    padding?: Size;
    backgroundColor?: string;
    borderRadius?: boolean;
}

const ContainerStyled = styled.div<ContainerProps>`
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    height: ${props => props.height};
    padding: ${props => props.padding}px;
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.borderRadius ? "8px" : "0"};
    margin: auto;
`;

export const Container = ({
                              children,
                              width = "100%",
                              height = "auto",
                              padding = Size.LEVEL_ONE,
                              backgroundColor = "transparent",
                              maxWidth = "none",
                              borderRadius = false
                          }: ContainerProps) => {
    return <ContainerStyled
        width={width} height={height} padding={padding} backgroundColor={backgroundColor}
        maxWidth={maxWidth} borderRadius={borderRadius}
    >
        {children}
    </ContainerStyled>;
};

export default Container;
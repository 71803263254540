import React, {useEffect, useState} from "react";
import Container from "../../../components/Container";
import Flex, {FlexItem} from "../../../components/Flex";
import Tag from "../../../components/Tag";
import {Size} from "../../../props/common";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/Button";
import {grey30, lightBlue, lightGreen, lightRed} from "../../../config/style";
import {fetchAPI} from "../../../config/fetch";
import {_sortMatchesByTableNumber, MatchProps} from "../matchProps";
import io, {Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter";
import env from "../../../config/env";
import Swal from "sweetalert2";
import Divider from "../../../components/Divider";
import Link from "../../../components/Link";
import Modal from "../../../components/Modal";
import Title from "../../../components/Title";
import Checkbox from "../../../components/Checkbox";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(env.SOCKET, {transports: ["websocket"]});
const UpdateMatch = () => {
    const [matches, setMatches] = useState<Array<MatchProps>>([]);
    const [matchToUpdate, setMatchToUpdate] = useState<any>({});
    const [readyToUpdate, setReadyToUpdate] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<any>({});
    const [disableButtons, setDisableButtons] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<number>(-1);

    const _freezeScores = (match: MatchProps) => {
        Swal.fire({
            title: "Do you really want to freeze scores ?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                fetchAPI(`/matches/individual/update-frozen`, "POST", {...match, is_frozen: true})
                    .then(res => {
                        if (res.errMsg) alert(res.errMsg);
                        setReadyToUpdate(true);
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Thank you !",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    })
                    .catch(err => {
                        alert(`An error occurred when updating score => ${err}"`);
                    });
            }
        });
    };

    useEffect(() => {
        setQueryParams(Object.fromEntries(new URLSearchParams(window.location.search).entries()));
    }, []);

    useEffect(() => {
        socket.on("updated-current-round", (readyToUpdate, callback) => {
            setReadyToUpdate(readyToUpdate);
        });
    }, []);

    useEffect(() => {
        fetchAPI(`/matches/Major2025/individual/rounds`)
            .then(res => {
                if (Object.keys(queryParams).length) {
                    setMatches(_sortMatchesByTableNumber(res.filter((match: MatchProps) => match.table_number >= queryParams.from && match.table_number <= queryParams.to)));
                } else {
                    setMatches(_sortMatchesByTableNumber(res));
                }
                setReadyToUpdate(false);
            });
    }, [readyToUpdate, queryParams]);

    const _setDelay = () => {
        setDisableButtons(true);
        setTimeout(() => {
            setDisableButtons(false);
        }, 350);
    };

    const updateScore = (newScore: number, idMatch: number, player: string) => {
        let matchesTmp = [...matches];
        // @ts-ignore
        matchesTmp[idMatch][player] = newScore >= 0 ? newScore : 0;
        setMatchToUpdate(matchesTmp[idMatch]);
        setMatches(matchesTmp);
        _setDelay();
    };

    const updateSuddenDeath = (idMatch: number, suddenDeath: boolean) => {
        let matchesTmp = [...matches];
        // @ts-ignore
        matchesTmp[idMatch]["sudden_death"] = suddenDeath;
        setMatchToUpdate(matchesTmp[idMatch]);
        setMatches(matchesTmp);
        _setDelay();
    };

    const updateShoots = (newShootsScore: number, idMatch: number, shootsKey: string) => {
        let matchesTmp = [...matches];
        // @ts-ignore
        matchesTmp[idMatch][shootsKey] = newShootsScore >= 0 ? newShootsScore : 0;
        setMatchToUpdate(matchesTmp[idMatch]);
        setMatches(matchesTmp);
        _setDelay();
    };

    const _playerLastnameUppercase = (playerName: string): string => {
        const playerNameSplitted: string[] = playerName.split(" ");
        const playerLastnameUppercased = playerNameSplitted[playerNameSplitted.length - 1].toUpperCase();
        playerNameSplitted.pop();
        return `${playerNameSplitted.join(" ")} ${playerLastnameUppercased}`;
    };

    useEffect(() => {
        if (Object.keys(matchToUpdate).length > 0) {
            setMatchToUpdate({});
            fetchAPI(`/matches/individual/update-scores/${matchToUpdate.id}`, "POST", matchToUpdate)
                .then(res => {
                    if (res.errMsg) alert(res.errMsg);
                    socket.emit("update-score", matchToUpdate);
                })
                .catch(err => {
                    alert(`An error occurred when updating score => ${err}"`);
                });
        }
    }, [matchToUpdate]);

    const ShootsModal = () => {
        if (openModal < 0) return null;
        return <Modal open>
            <Flex>
                <FlexItem marginTop={0}>
                    <Title>Shoots score</Title>
                </FlexItem>
                <FlexItem marginTop={0} width="auto">
                    <Flex justifyContent="flex-end">
                        <Link onClick={() => setOpenModal(-1)} noUnderline>
                            <Title size={Size.LEVEL_FOUR}>✕</Title>
                        </Link>
                    </Flex>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <Tag borderRadius="8px">
                        {_playerLastnameUppercase(matches[openModal].player_one.fullname)}
                    </Tag>
                </FlexItem>
                <FlexItem width="auto" minWidth="125px">
                    <Tag borderRadius="8px">
                        {matches[openModal].score_player_one}
                        <Divider direction="vertical" margin={Size.LEVEL_TWO}/>
                        {matches[openModal].score_player_two}
                    </Tag>
                </FlexItem>
                <FlexItem>
                    <Tag borderRadius="8px">
                        {_playerLastnameUppercase(matches[openModal].player_two.fullname)}
                    </Tag>
                </FlexItem>
            </Flex>
            <Divider margin={Size.LEVEL_FOUR} size={"100%"}/>
            <Flex>
                <FlexItem width="25%">
                    <Flex justifyContent="center">
                        <Title>Shoots player 1</Title>
                    </Flex>
                </FlexItem>
                <FlexItem width="25%"></FlexItem>
                <FlexItem width="25%">
                    <Flex justifyContent="center">
                        <Title>Shoots player 2</Title>
                    </Flex>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem width="25%">
                    <Flex>
                        <Button
                            color={lightRed}
                            borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                            height={`${Size.LEVEL_TEN}px`}
                            onClick={() => updateShoots(matches[openModal].shoots_player_one - 1, openModal, "shoots_player_one")}
                            disabled={matches[openModal].is_frozen || disableButtons}
                        >
                            -
                        </Button>
                        <Tag borderRadius="0">{matches[openModal].shoots_player_one}</Tag>
                        <Button
                            color={lightGreen}
                            borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                            height={`${Size.LEVEL_TEN}px`}
                            onClick={() => updateShoots(matches[openModal].shoots_player_one + 1, openModal, "shoots_player_one")}
                            disabled={matches[openModal].is_frozen || disableButtons}
                        >
                            +
                        </Button>
                    </Flex>
                </FlexItem>
                <FlexItem width="25%"/>
                <FlexItem width="25%">
                    <Flex>
                        <Button
                            color={lightGreen}
                            borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                            height={`${Size.LEVEL_TEN}px`}
                            onClick={() => updateShoots(matches[openModal].shoots_player_two + 1, openModal, "shoots_player_two")}
                            disabled={matches[openModal].is_frozen || disableButtons}
                        >
                            +
                        </Button>
                        <Tag borderRadius="0">{matches[openModal].shoots_player_two}</Tag>
                        <Button
                            color={lightRed}
                            borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                            height={`${Size.LEVEL_TEN}px`}
                            onClick={() => updateShoots(matches[openModal].shoots_player_two - 1, openModal, "shoots_player_two")}
                            disabled={matches[openModal].is_frozen || disableButtons}
                        >
                            -
                        </Button>
                    </Flex>
                </FlexItem>
            </Flex>
            <Spacer size={Size.LEVEL_ELEVEN}/>
            <Flex>
                <Button color={lightBlue} onClick={() => setOpenModal(-1)} fontSize="20px">
                    Done
                </Button>
            </Flex>
        </Modal>;
    };

    return (
        <>
            <ShootsModal/>
            <Flex>
                <Container padding={Size.LEVEL_ZERO}>
                    {matches.map(((match: MatchProps, index: number) => {
                        const suddenDeath = match.sudden_death || ((match.score_player_one === match.score_player_two) ? false : undefined);
                        return <div key={index}>
                            {
                                index > 0 && <>
                                    <Spacer size={Size.LEVEL_ONE}/>
                                    <hr style={{borderWidth: 1, borderStyle: "solid", borderColor: grey30}}/>
                                    <Spacer size={Size.LEVEL_ONE}/>
                                </>
                            }
                            <Flex alignItems="flex-end" key={`top-level-${index}-${match.id}`}>
                                <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                    <Flex>
                                        <div style={{width: "48px"}}>
                                            <Tag fontSize={Size.LEVEL_THREE} height={Size.LEVEL_SIX}>
                                                {match.table_number}
                                            </Tag>
                                        </div>
                                        <Spacer horizontal size={Size.LEVEL_TWO}/>
                                        <Tag fontSize={20} height={Size.LEVEL_FIVE}>
                                            {match.referee}
                                        </Tag>
                                    </Flex>
                                </FlexItem>
                                <div style={{width: "12.5vw", marginRight: "16px", textAlign: "center"}}/>
                                <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                    <Flex justifyContent="space-between">
                                        {
                                            match.round > 6 &&
                                            <Container
                                                backgroundColor="white" borderRadius height="48px"
                                                padding={0}
                                            >
                                                <div style={{paddingLeft: "24px", paddingRight: "24px"}}>
                                                    <Flex
                                                        justifyContent={match.score_player_one === match.score_player_two ? "space-between" : "flex-start"}
                                                    >
                                                        <Checkbox
                                                            id={`sudden-death-${index}`} label="Sudden death"
                                                            disabled={(match.score_player_one === match.score_player_two && !match.sudden_death) || match.is_frozen}
                                                            checked={suddenDeath}
                                                            onChange={(event: any) => updateSuddenDeath(index, event.target.checked)}
                                                        />
                                                        {
                                                            match.score_player_one === match.score_player_two &&
                                                            <>
                                                                <Divider
                                                                    direction="vertical"
                                                                    margin={Size.LEVEL_THREE}
                                                                />
                                                                <Link
                                                                    onClick={() => setOpenModal(index)}
                                                                    size={Size.LEVEL_TWO}
                                                                >
                                                                    Shoots ?
                                                                </Link>
                                                            </>
                                                        }
                                                    </Flex>
                                                </div>
                                            </Container>
                                        }
                                        <Spacer horizontal size={Size.LEVEL_TWO}/>
                                        <Button
                                            color={lightBlue}
                                            fontSize={`14px`}
                                            disabled={match.is_frozen}
                                            onClick={() => _freezeScores(match)}
                                            width="150px" borderRadius="8px"
                                            height={`${Size.LEVEL_SIX}px`}
                                        >
                                            <Flex>
                                                <Title marginBottom={0} size={Size.LEVEL_TWO}>SAVE</Title>
                                                <Spacer horizontal size={Size.LEVEL_ONE}/>
                                                <Title marginBottom={0} size={Size.LEVEL_THREE}>✓</Title>
                                            </Flex>
                                        </Button>
                                    </Flex>
                                </FlexItem>
                            </Flex>

                            <Spacer size={Size.LEVEL_ONE}/>

                            <Flex alignItems="flex-end" key={`bottom-level-${index}-${match.id}`}>
                                <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                    <Flex>
                                        <Button
                                            color={lightRed}
                                            borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_one - 1, index, "score_player_one")}
                                            disabled={match.is_frozen || disableButtons}
                                        >
                                            -
                                        </Button>
                                        <Tag
                                            borderRadius="0">{_playerLastnameUppercase(match.player_one.fullname)}</Tag>
                                        <Button
                                            color={lightGreen}
                                            borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_one + 1, index, "score_player_one")}
                                            disabled={match.is_frozen || disableButtons}
                                        >
                                            +
                                        </Button>
                                    </Flex>
                                </FlexItem>
                                <div style={{width: "12.5vw", marginRight: "16px", textAlign: "center"}}>
                                    <Flex>
                                        <FlexItem width="100%" marginTop={Size.LEVEL_ZERO}>
                                            <Tag borderRadius="8px">
                                                {match.score_player_one}
                                                <Divider direction="vertical" margin={Size.LEVEL_TWO}/>
                                                {match.score_player_two}
                                            </Tag>
                                        </FlexItem>
                                    </Flex>
                                </div>
                                <FlexItem width="45vw" marginTop={Size.LEVEL_ZERO}>
                                    <Flex>
                                        <Button
                                            color={lightGreen}
                                            borderRadius={`${Size.LEVEL_ONE}px 0 0 ${Size.LEVEL_ONE}px`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_two + 1, index, "score_player_two")}
                                            disabled={match.is_frozen || disableButtons}
                                        >
                                            +
                                        </Button>

                                        <Tag borderRadius="0">
                                            {_playerLastnameUppercase(match.player_two.fullname)}
                                        </Tag>

                                        <Button
                                            color={lightRed}
                                            borderRadius={`0 ${Size.LEVEL_ONE}px ${Size.LEVEL_ONE}px 0`}
                                            height={`${Size.LEVEL_TEN}px`}
                                            onClick={() => updateScore(match.score_player_two - 1, index, "score_player_two")}
                                            disabled={match.is_frozen || disableButtons}
                                        >
                                            -
                                        </Button>
                                    </Flex>
                                </FlexItem>
                            </Flex>
                        </div>;
                    }))}
                </Container>
            </Flex>
        </>
    );
};

export default UpdateMatch;

export enum CountriesEnum {
    FRANCE = "FRANCE",
    BELGIQUE = "BELGIQUE"
}

export interface PlayerProps {
    firstname: string;
    lastname: string;
    fullname: string;
    birthdate: string;
    club: string;
    club_short: string;
    country: CountriesEnum | string;
    star: number;
    licence_number: string;
}

export interface TeamProps {
    name: string,
    all_players: PlayerProps[],
    country?: CountriesEnum,
    short: string
}

export interface MatchProps {
    id: number;
    player_one: PlayerProps;
    player_two: PlayerProps;
    referee: string;
    round: number;
    score_player_one: number;
    score_player_two: number;
    table_number: number;
    category: string;
    team?: boolean;
    is_frozen?: boolean;
    substitute_player_one: PlayerProps;
    substitute_player_two: PlayerProps;
    sudden_death?: boolean;
    shoots_player_one: number;
    shoots_player_two: number;
}

export interface TeamMatchProps extends MatchProps {
    score_team_one: number;
    score_team_two: number;
    team_one: TeamProps;
    team_two: TeamProps;
    all_single_matches: MatchProps[];
}

export const emptyMatch = {
    category: "",
    id: -1,
    player_one: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        club_short: "",
        country: "",
        star: 0,
        licence_number: ""
    },
    player_two: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        club_short: "",
        country: "",
        star: 0,
        licence_number: ""
    },
    substitute_player_one: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        club_short: "",
        country: "",
        star: 0,
        licence_number: ""
    },
    substitute_player_two: {
        firstname: "",
        lastname: "",
        fullname: "",
        birthdate: "",
        club: "",
        club_short: "",
        country: "",
        star: 0,
        licence_number: ""
    },
    referee: "",
    round: 0,
    score_player_one: 0,
    score_player_two: 0,
    shoots_player_one: 0,
    shoots_player_two: 0,
    table_number: 0,
    team: false,
    is_frozen: false
};

export const teamEmptyMatch = {
    ...emptyMatch,
    all_single_matches: [],
    team_one: {
        name: "",
        all_players: [],
        short: ""
    },
    team_two: {
        name: "",
        all_players: [],
        short: ""
    },
    score_team_one: 0,
    score_team_two: 0,
    is_frozen: false
};

export interface PlayerProps {
    firstname: string;
    lastname: string;
}

export const _sortMatchesByTableNumber = (array: any[]) => {
    return array.sort((a, b) => (a.table_number > b.table_number) ? 1 : ((b.table_number > a.table_number) ? -1 : 0));
};

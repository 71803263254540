import AllMatchesComponent from "../../components/allMatches/allMatches";

const CurrentRound = () => {
    return (
        <div>
            <AllMatchesComponent type={'individual'}/>
        </div>
    );
};

export default CurrentRound;
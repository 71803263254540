import React, {useEffect, useState} from "react";
import DashboardLayout from "../../components/DashboardLayout";
import {fetchAPI} from "../../config/fetch";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Container from "../../components/Container";
import {Size} from "../../props/common";
import {MatchProps, TeamMatchProps} from "../match/matchProps";
import Accordion from "../../components/accordion/Accordion";
import AccordionItem from "../../components/accordion/AccordionItem";
import AccordionHeader from "../../components/accordion/AccordionHeader";
import AccordionBody from "../../components/accordion/AccordionBody";
import Spacer from "../../components/Spacer";
import Title from "../../components/Title";
import Nav from "../../components/Nav";

interface RoundsProps {
    rounds: number[],
    matches: TeamMatchProps[]
}

const TeamRounds = () => {
    const [rounds, setRounds] = useState<RoundsProps>({matches: [], rounds: []});
    const [match, setMatch] = useState<TeamMatchProps>();
    const [matchId, setMatchId] = useState(-1);

    useEffect(() => {
        fetchAPI("/rounds/Major2025/teams")
            .then(res => {
                setRounds(res);
            })
            .catch(err => {
                console.log(err);
                alert(err);
            });
    }, []);

    useEffect(() => {
        if (matchId > -1) {
            fetchAPI(`/matches/team/${matchId}`)
                .then(res => {
                    setMatch(res);
                })
                .catch(err => {
                    console.log(err);
                    alert(err);
                });
        }
    }, [matchId]);

    return (
        <DashboardLayout currentPage="rounds" sidebar={false}>
            {
                <Container padding={Size.LEVEL_TWO} maxWidth="1348px">
                    <Nav currentPage="rounds" parent="team"/>
                    {
                        Object.keys(rounds).length && rounds.matches.length ?
                            <>
                                <Spacer size={Size.LEVEL_THREE}/>
                                <Accordion>
                                    {
                                        rounds.rounds.map((round: number, index: number) => {
                                            return <AccordionItem key={index}
                                                                  className={index === 0 ? "is-active" : ""}>
                                                <AccordionHeader>{`Round ${round}`}</AccordionHeader>
                                                <AccordionBody>
                                                    <Table>
                                                        <TableHead>
                                                            <tr>
                                                                <td style={{minWidth: "75px"}} width="6%">Tables</td>
                                                                <td style={{minWidth: "175px"}} width="24%">Team 1</td>
                                                                <td style={{minWidth: "100px"}} width="10%">Score</td>
                                                                <td style={{minWidth: "175px"}} width="24%">Team 2</td>
                                                                <td style={{minWidth: "175px"}} width="25%">Referee</td>
                                                                <td style={{minWidth: "75px"}} width="6%">Details</td>
                                                            </tr>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                rounds.matches
                                                                    .filter(match => match.round === round)
                                                                    .map((match: TeamMatchProps, index: number) => {
                                                                        return <tr key={index}>
                                                                            <td>{match.table_number} - {match.table_number + 3}</td>
                                                                            <td>{match.team_one.name}</td>
                                                                            <td>{match.score_team_one} - {match.score_team_two}</td>
                                                                            <td>{match.team_two.name}</td>
                                                                            <td>{match.referee}</td>
                                                                            <td style={{textAlign: "center"}}>
                                                                                <img
                                                                                    src={require("../../assets/icons/loupe.png")}
                                                                                    width="18px"
                                                                                    alt="Magnifying glass"
                                                                                    onClick={() => setMatchId(match.id)}
                                                                                />
                                                                            </td>
                                                                        </tr>;
                                                                    })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </AccordionBody>
                                            </AccordionItem>;
                                        })
                                    }
                                </Accordion>
                            </>
                            : <>
                                <Spacer/>
                                <Title>Loading matches...</Title>
                            </>
                    }
                    <div className={`team-match-wrapper ${matchId > -1 ? "activated" : ""}`}>
                        <div className={`team-match-container ${matchId > -1 ? "activated" : ""}`}>
                            <div className="team-match-container-header">
                                <img
                                    src={require("../../assets/icons/close.png")}
                                    alt="Close modal"
                                    onClick={() => setMatchId(-1)}
                                />
                            </div>
                            <Spacer/>
                            <div className="team-match-container-body">
                                <Container padding={Size.LEVEL_TWO}>
                                    <Table>
                                        <TableHead>
                                            <tr>
                                                <td style={{minWidth: "180px"}} width="42.5%">
                                                    <Title size={Size.LEVEL_TWO} marginBottom={0}>
                                                        {match?.team_one.name}
                                                    </Title>
                                                </td>
                                                <td style={{minWidth: "100px"}} width="15%">
                                                    <Title size={Size.LEVEL_TWO} marginBottom={0} centered>
                                                        {match?.score_team_one} - {match?.score_team_two}
                                                    </Title>
                                                </td>
                                                <td style={{minWidth: "180px"}} width="42.5%">
                                                    <Title size={Size.LEVEL_TWO} marginBottom={0}>
                                                        {match?.team_two.name}
                                                    </Title>
                                                </td>
                                            </tr>
                                        </TableHead>
                                        {
                                            (match?.all_single_matches && match?.all_single_matches.length) ?
                                                <TableBody>
                                                    {
                                                        match.all_single_matches.map((match: MatchProps, index: number) => {
                                                            return <tr key={index}>
                                                                <td style={{minWidth: "250px"}} width="42.5%">
                                                                    {match.player_one.fullname}
                                                                </td>
                                                                <td style={{minWidth: "100px", textAlign: "center"}}
                                                                    width="15%">
                                                                    {match.score_player_one} - {match.score_player_two}
                                                                </td>
                                                                <td style={{minWidth: "250px"}} width="42.5%">
                                                                    {match.player_two.fullname}
                                                                </td>
                                                            </tr>;
                                                        })
                                                    }
                                                </TableBody>
                                                : null
                                        }
                                    </Table>
                                </Container>
                            </div>
                        </div>
                    </div>
                </Container>
            }
        </DashboardLayout>
    );
};

export default TeamRounds;

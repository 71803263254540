import AllMatchesComponent from "../components/allMatches/allMatches";
import {useParams} from "react-router-dom";

const AllMatches = () => {
    const {type} = useParams();
    if (type === "team") {
        return <AllMatchesComponent type="team"/>;
    }
    return <AllMatchesComponent type="individual"/>;
};

export default AllMatches;
import React from "react";
import styled from "styled-components";
import {darkBlue, grey75} from "../config/style";

interface CheckboxPros {
    children?: any;
    label?: string;
    id: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: any;
}

const CheckboxWrapperStyled = styled.div`
    height: 48px;

    label.container {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        padding-right: 32px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
                ~ {
                    .checkmark {
                        background-color: color-mix(in srgb, ${darkBlue} 65%, white 35%);;

                        &:after {
                            display: block;
                        }
                    }
                }
            }

            &:disabled {
                ~ {
                    .checkmark {
                        background-color: ${grey75};
                        border: 2px solid ${grey75};
                        cursor: not-allowed;
                    }
                }
            }
        }

        .checkmark {
            &:after {
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .checkmark {
        position: absolute;
        box-sizing: border-box;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid ${darkBlue};

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
`;

const Checkbox = ({label, id, disabled = false, checked, onChange = null}: CheckboxPros) => {
    return <CheckboxWrapperStyled>
        <label className="container" htmlFor={id}>
            {label}
            <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange}/>
            <span className="checkmark"/>
        </label>
    </CheckboxWrapperStyled>
        ;
};

export default Checkbox;
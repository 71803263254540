import React, {useEffect, useState} from 'react';
import DashboardLayout from "../../components/DashboardLayout";
import {fetchAPI} from "../../config/fetch";
import Table from "../../components/table/Table";
import TableHead from "../../components/table/TableHead";
import TableBody from "../../components/table/TableBody";
import Container from "../../components/Container";
import {Size} from "../../props/common";
import {MatchProps} from "../match/matchProps";
import Accordion from "../../components/accordion/Accordion";
import AccordionItem from "../../components/accordion/AccordionItem";
import AccordionHeader from "../../components/accordion/AccordionHeader";
import AccordionBody from "../../components/accordion/AccordionBody";
import Spacer from "../../components/Spacer";
import Nav from "../../components/Nav";
import Title from "../../components/Title";

interface RoundsProps {
    rounds: number[],
    matches: MatchProps[]
}

const IndividualRounds = () => {
    const [rounds, setRounds] = useState<RoundsProps>({matches: [], rounds: []});

    useEffect(() => {
        fetchAPI('/rounds/Major2025/individual')
            .then(res => {
                setRounds(res);
            })
            .catch(err => {
                console.log(err);
                alert(err);
            })
    }, [])

    return (
        <DashboardLayout currentPage="rounds" sidebar={false}>
            {
                <Container padding={Size.LEVEL_TWO} maxWidth='1348px'>
                    <Nav currentPage="rounds" parent="individual"/>
                    {
                        Object.keys(rounds).length && rounds.matches.length ?
                            <>
                                <Spacer size={Size.LEVEL_THREE}/>
                                <Accordion>
                                    {
                                        rounds.rounds.map((round: number, index: number) => {
                                            return <AccordionItem key={index}
                                                                  className={index === 0 ? 'is-active' : ''}>
                                                <AccordionHeader>{`Round ${round}`}</AccordionHeader>
                                                <AccordionBody>
                                                    <Table>
                                                        <TableHead>
                                                            <tr>
                                                                <td width="10%">Table</td>
                                                                <td width="20%">Player 1</td>
                                                                <td width="15%">Score</td>
                                                                <td width="20%">Player 2</td>
                                                                <td width="20%">Referee</td>
                                                                <td width="15%">Category</td>
                                                            </tr>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                rounds.matches
                                                                    .filter(match => match.round === round)
                                                                    .map((match: MatchProps, index: number) => {
                                                                        return <tr key={index}>
                                                                            <td>{match.table_number}</td>
                                                                            <td>{match.player_one.fullname}</td>
                                                                            <td>{match.score_player_one} - {match.score_player_two}</td>
                                                                            <td>{match.player_two.fullname}</td>
                                                                            <td>{match.referee}</td>
                                                                            <td>{match.category}</td>
                                                                        </tr>
                                                                    })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </AccordionBody>
                                            </AccordionItem>
                                        })
                                    }
                                </Accordion>
                            </>
                            : <>
                                <Spacer/>
                                <Title>Loading matches...</Title>
                            </>
                    }
                </Container>
            }
        </DashboardLayout>
    )
}

export default IndividualRounds;

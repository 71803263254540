import styled from "styled-components";

interface ModalProps {
    children: any;
    open?: boolean;
}

const ModalStyled = styled.div<ModalProps>`
    top: ${props => props.open ? "0" : "-1000px"};
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 1000;
    padding: 96px;
    
    &:before {
        content: "";
        position: absolute;
        height: 575px;
        width: calc(100% - 128px);
        border-radius: 8px;
        top: 72px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #efefef;
        z-index: -1;
    }
`;

const Modal = ({children, open = false}: ModalProps) => {
    return <ModalStyled className="modal" open={open}>
        {children}
    </ModalStyled>;
};

export default Modal;